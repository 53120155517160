<template>
<main>
    <main-navbar></main-navbar>
    <main-sidebar></main-sidebar>
    <div class="main-wrapper">
        <!-- Main Content-->
        <div class="main-content pr-0" id="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>{{$route.meta.breadcrumb['0'].text}}</h1>
                    <div class="section-header-breadcrumb flex-wrap custom-breadcrumb">
                        <b-breadcrumb :items="$route.meta.breadcrumb"></b-breadcrumb>
                    </div>
                </div>
                <div class="section-body container_job">
                    <h2 class="section-title">{{$route.meta.pageTitle}}</h2>
                    <router-view :key="$route.path"  ></router-view>
                    
                </div>
            </section>
        </div>
    </div>
</main>
</template>

<script lang="ts">
import Vue from 'vue'
import VueMeta from 'vue-meta'
import MainSidebar from "../components/admin/MainSidebar.vue"
import MainNavbar from "../components/admin/MainNavbar.vue"
Vue.use(VueMeta)
export default Vue.extend({
    computed: {
    
    },
    methods: {
    },
    created() {
    },
     components: {
        MainSidebar,
        MainNavbar
    },
})
</script>

<style scoped>
/* .navbar {
    position: relative;
} */
</style>
