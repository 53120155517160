<template>
<div class="main-sidebar" id="main-sidebar-contant">
    <b-sidebar id="main-sidebar" title="" shadow :visible="isOpen" v-on:hidden="sidebarHide" v-on:shown="sidebarShow" no-header :no-close-on-route-change="true">
    <aside id="sidebar-wrapper">
        <ul class="sidebar-menu mt-2 pb-5">
            <li class="menu-header"> <strong>エフコネ</strong> 管理画面</li>

            <li v-bind:class="{ 'active' : this.$route.name === 'admin-validation-id-card' || this.$route.query.page == 'id-card'}">
                <router-link to="/admin/validation/id-card">
                    <i class="fas fa-id-card" aria-hidden="true"></i>
                    <span>身分証を認証する</span>
                 </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-validation-skill' || this.$route.query.page == 'skills' }">
                <router-link to="/admin/validation/skills">
                    <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                    <span>ユーザースキル/資格一覧</span>
                </router-link>
            </li>
           
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-users' ||  this.$route.name === 'admin-users-detail' }">
                <router-link to="/admin/users">
                    <i class="fas fa-user-circle" aria-hidden="true"></i>
                    <span>ユーザー 管理</span>
                </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-category' ||  this.$route.name === 'admin-category-detail' }">
                <router-link to="/admin/category">
                    <i class="fas fa-tag" aria-hidden="true"></i>
                    <span>スキル/資格マスタ</span>
                </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-admin-post' ||  this.$route.name === 'admin-admin-post-detail' }">
                <router-link to="/admin/admin-post">
                    <i class="fas fa-bullhorn" aria-hidden="true"></i>
                    <span>運営からのお知らせ</span>
                </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-user-post' ||  this.$route.name === 'admin-user-post-detail' }">
                <router-link to="/admin/user-post">
                    <i class="fas fa-info-circle" aria-hidden="true"></i>
                    <span>自由投稿管理</span>
                </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-jobs' ||  this.$route.name === 'admin-jobs-detail' }">
                <router-link to="/admin/jobs">
                    <i class="fa fa-briefcase" aria-hidden="true"></i>
                    <span>仕事投稿管理</span>
                </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-ngWord' }">
                <router-link to="/admin/ngWord">
                    <i class="fas fa-bookmark" aria-hidden="true"></i>
                    <span>NGワード管理</span>
                </router-link>
            </li>
            
            <li v-bind:class="{ 'active' : this.$route.name === 'admin-video' }">
                <router-link to="/admin/video">
                    <i class="fas fa-video"></i>
                    <span>動画アップロード</span>
                </router-link>
            </li>

            <li v-bind:class="{ 'active' : this.$route.name === 'admin-account' ||  this.$route.name === 'admin-account-detail' }">
                <router-link to="/admin/account">
                    <i class="fas fa-lock" aria-hidden="true"></i>
                    <span>ログインID/PW 管理</span>
                </router-link>
            </li>
        </ul>
    </aside>
    </b-sidebar>
</div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            isOpen: true
        }
    },
    props: {
        sidebar: String
    },
    methods: {
        checkActive(sidebar) {
            let isActive = sidebar===this.sidebar;
            return { active: isActive};
        },
        sidebarHide() {
            document.getElementById("main-content").style.paddingLeft = "20px";
            document.getElementById("main-sidebar-contant").style.display = "none";
            document.getElementById("main-navbar").style.left = "20px";
        },
        sidebarShow() {
            document.getElementById("main-content").style.paddingLeft = "270px";
            document.getElementById("main-sidebar-contant").style.display = "block";
            document.getElementById("main-navbar").style.left = "270px";
        }
    },
    computed: {
        page_data() {
            return this.$store.getters.getPageData;
        }
    },
})
</script>

<style lang="scss">

</style>
